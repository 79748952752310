import axios, { resHandle } from "@/plugins/axios";

/**
 * @description 获取分类
 * @param vCom Vue组件
 */
const GetCategory = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/knowledge/task/category", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取标注任务列表
 * @param vCom Vue组件
 */
const GetBiaozhuyuan = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/user/markers", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取标注任务列表
 * @param vCom Vue组件
 */
const GetRenwuList = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/tasks", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 删除任务
 * @param vCom Vue组件
 */
const DeleteRenwu = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.delete("api/annotator/task", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 搜索书籍
 * @param vCom Vue组件
 */
const SearchBook = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/knowledge/book", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取书籍章节
 * @param vCom Vue组件
 */
const GetBookZhangjie = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/knowledge/book", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取任务选项
 * @param vCom Vue组件
 */
const GetRenwuOption = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/task", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 新建标注任务
 * @param vCom Vue组件
 */
const AddRenwu = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/task", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 查看标注进度
 * @param vCom Vue组件
 */
const GetJindu = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/process", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 分配标注员
 * @param vCom Vue组件
 */
const Fenpei = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.put("api/annotator/task", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 查看标注任务详情页
 * @param vCom Vue组件
 */
const RenwuDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/tasks", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 编辑标注任务分类
 * @param vCom Vue组件
 */
const EditFenlei = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/knowledge/task/category", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取语义类型标注的选项
 * @param vCom Vue组件
 */
const GetBiaozhuOption = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/type", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 保存标注
 * @param vCom Vue组件
 */
const SaveBiaozhu = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/save", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 提交审核，审核通过，打回修改
 * @param vCom Vue组件
 */
const TijiaoBiaozhu = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/save", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取进度统计概览
 * @param vCom Vue组件
 */
const GetOverview = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/process/overview").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取进度统计状态左边
 * @param vCom Vue组件
 */
const GetRenwuZhuangtaiLeft = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/process/category/tasks").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取进度统计状态右边
 * @param vCom Vue组件
 */
const GetRenwuZhuangtaiRight = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/process/category/tasks", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取任务分配数量
 * @param vCom Vue组件
 */
const GetFenpeiNum = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/process/user/tasks").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 默认选中的语义类型
 * @param vCom Vue组件
 */
const GetTuijianType = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/entity", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取关联的概念实体
 * @param vCom Vue组件
 */
const GetEntity = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/entity", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取实体属性
 * @param vCom Vue组件
 */
const GetShuxing = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/knowledge/attributes", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};

/**
 * @description 获取所有可选的语义类型，可搜索
 * @param vCom Vue组件
 */
const GetSechemaList = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/knowledge/sechema_list", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            }).catch((err) => {
                reject(err);
            });
        }).catch((err) => {
            reject(err);
        });
    });
};
/**
 * @description 获取某个专题的出现关系和出现关系值
 * @param vCom Vue组件
 */
const GetChuxianyuList = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/book/entity_chuxianyu_list", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            }).catch((err) => {
                reject(err);
            });
        }).catch((err) => {
            reject(err);
        });
    });
};
/**
 * @description 获取某个标注任务的所有绑定的专题实体的所有出现关系
 * https://app.apifox.com/project/3433166/apis/api-181442648
 * @param vCom Vue组件
 */
const GetChuxianyuList2 = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/book/annotator_entity_chuxianyu_list", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            }).catch((err) => {
                reject(err);
            });
        }).catch((err) => {
            reject(err);
        });
    });
};
/**
 * @description 标注审核通过
 * @param vCom Vue组件
 */
const Shenhetongguo = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/shenhe", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            }).catch((err) => {
                reject(err);
            });
        }).catch((err) => {
            reject(err);
        });
    });
};
/**
 * @description 工作量统计
 * @param vCom Vue组件
 */
const Statistics = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/process/user/workload/statistics", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            }).catch((err) => {
                reject(err);
            });
        }).catch((err) => {
            reject(err);
        });
    });
};
/**
 * @description 通用导出excel
 * @param vCom Vue组件
 */
const ExportExcel = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/util/export_excel", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                reject(err);
            });
        }).catch((err) => {
            reject(err);
        });
    });
};
/**
 * @description 获取专题图谱
 * @param vCom Vue组件
 */
const GetZhuantiTupu = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/knowledge/entity/tupu_level", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                reject(err);
            });
        }).catch((err) => {
            reject(err);
        });
    });
};
/**
 * @description 修改标注任务
 * https://app.apifox.com/project/3433166/apis/api-169428967
 * @param task_id 任务id
 */
const PostEditRenwu = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/annotator_task", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                reject(err);
            });
        }).catch((err) => {
            reject(err);
        });
    });
};
/**
 * @description 返回某任务下所有专题的属性列表
 * @url https://app.apifox.com/project/3433166/apis/api-206116569
 * @api /api/knowledge/all_zhuanti_attr_list
 * @param task_id 任务id
 * @param search
 */
const GetRenwuAttr = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/knowledge/all_zhuanti_attr_list", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                reject(err);
            });
        }).catch((err) => {
            reject(err);
        });
    });
};
/**
 * @description 工作量统计-按任务名展示详情数据
 * @url https://app.apifox.com/project/3433166/apis/api-213710879
 * @api /api/annotator/process/user/workload/stat_by_task
 */
const GetDataByTask = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/process/user/workload/stat_by_task").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                reject(err);
            });
        }).catch((err) => {
            reject(err);
        });
    });
};
/**
 * @description 工作量统计-按账户名展示详情数据
 * @url https://app.apifox.com/project/3433166/apis/api-213632187
 * @api /api/annotator/process/user/workload/stat_by_account
 */
const GetDataByAccount = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/process/user/workload/stat_by_account").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                reject(err);
            });
        }).catch((err) => {
            reject(err);
        });
    });
};
/**
 * @description 创建智能标注
 * @url https://app.apifox.com/project/3433166/apis/api-216137062
 * @api api/annotator/auto_annotator
 */
const ChangeZhinengBiaozhu = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/auto_annotator", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                reject(err);
            });
        }).catch((err) => {
            reject(err);
        });
    });
};
/**
 * @description 删除智能标注痕迹
 * @url https://app.apifox.com/project/3433166/apis/api-219013256
 * @api api/annotator/auto_annotator
 */
const DeleteZhinengBiaozhu = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.delete("api/annotator/auto_annotator", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                reject(err);
            });
        }).catch((err) => {
            reject(err);
        });
    });
};
export {
    Statistics, ExportExcel,
    GetCategory, GetBiaozhuyuan, GetRenwuList, DeleteRenwu, SearchBook, GetBookZhangjie,
    GetRenwuOption, AddRenwu, GetJindu, Fenpei, RenwuDetail, EditFenlei, GetBiaozhuOption,
    SaveBiaozhu, TijiaoBiaozhu, GetOverview, GetRenwuZhuangtaiLeft, GetRenwuZhuangtaiRight,
    GetFenpeiNum, GetTuijianType, GetEntity, GetShuxing, GetSechemaList, GetChuxianyuList,
    GetChuxianyuList2,
    Shenhetongguo, GetZhuantiTupu, PostEditRenwu, GetRenwuAttr,
    GetDataByTask, GetDataByAccount, ChangeZhinengBiaozhu, DeleteZhinengBiaozhu
}